<template>
  <input
    type="text"
    id="signupLocation"
    ref="gautocomplete"
    class="form-control label-require"
    placeholder="Where do you live (City, State & Country)?"
    autocomplete="off"
  />
  <input type="hidden" ref="address2" id="signupAddress2" />
  <input type="hidden" ref="city" id="signupCity" />
  <input type="hidden" ref="state" id="signupState" />
  <input type="hidden" ref="zip_code" id="signupZipCode" />
  <input type="hidden" ref="country" id="signupCountry" />
  <input type="hidden" ref="lat" id="signupLat" />
  <input type="hidden" ref="lng" id="signupLng" />
</template>

<script>
// var googleMapScript;
var autocomplete;

export default {
  name: 'LocationGmap',
  created() {
    const key = process.env.VUE_APP_GOOGLEMAPS_KEY;
    this.$loadScript(
      `https://maps.googleapis.com/maps/api/js?key=${key}&libraries=places&callback=initMap`
    )
      .then(() => {
        // Script is loaded, do something
        /**
         * this function is called as soon as the map is initialized
         */
      })
      .catch(() => {
        // Failed to fetch script
      });
  },
  mounted() {
    window.initMap = () => {
      autocomplete = new window.google.maps.places.Autocomplete(
        /** @type {!HTMLInputElement} */ (this.$refs.gautocomplete),
        { types: ['geocode'] }
      );
      autocomplete.addListener('place_changed', this.fillInAddress);
    };
  },
  methods: {
    fillInAddress() {
      //reset address field
      this.$refs.address2.value = '';
      this.$refs.city.value = '';
      this.$refs.state.value = '';
      this.$refs.zip_code.value = '';
      this.$refs.country.value = '';
      this.$refs.lng.value = '';
      this.$refs.lat.value = '';
      // Get the place details from the autocomplete object.
      const place = autocomplete.getPlace();
      let address2 = '';
      let zip_code = '';
      console.log('place ', place);

      // Get each component of the address from the place details,
      // and then fill-in the corresponding field on the form.
      // place.address_components are google.maps.GeocoderAddressComponent objects
      // which are documented at http://goo.gle/3l5i5Mr
      for (const component of place.address_components) {
        const componentType = component.types[0];

        switch (componentType) {
          case 'street_number': {
            address2 = `${component.long_name} ${address2}`;
            break;
          }

          case 'route': {
            address2 += component.long_name;
            break;
          }

          case 'postal_code': {
            zip_code = `${component.long_name}${zip_code}`;
            break;
          }

          case 'postal_code_suffix': {
            zip_code = `${zip_code}-${component.long_name}`;
            break;
          }
          case 'locality':
            // document.querySelector("#locality").value = component.long_name;
            this.$refs.city.value = component.long_name;
            break;

          case 'administrative_area_level_1': {
            this.$refs.state.value = component.long_name;
            break;
          }
          case 'country':
            this.$refs.country.value = component.short_name;
            break;
        }
      }
      // address2Field.value = address2;
      this.$refs.address2.value = address2;
      this.$refs.zip_code.value = zip_code;
      this.$refs.lng.value = place.geometry.location.lat();
      this.$refs.lat.value = place.geometry.location.lng();
    },
  },
  unmounted() {
    // var element = document.getElementById('gmapLocation');
    // if (typeof element == 'undefined' || element == null) return;
    // delete window.initMap;
    // document.head.removeChild(googleMapScript);
    // As an instance method inside a component
    const key = process.env.VUE_APP_GOOGLEMAPS_KEY;
    this.$unloadScript(
      `https://maps.googleapis.com/maps/api/js?key=${key}&libraries=places&callback=initMap`
    )
      .then(() => {
        // Script was unloaded successfully
      })
      .catch(() => {
        // Script couldn't be found to unload; make sure it was loaded and that you passed the same URL
      });
  },
};
</script>

<style scoped></style>
