<template>
  <div class="container-fluid px-lg-5 mb-3 margin-top-0421">
    <div class="row">
      <div class="col-md-6 offset-md-3">
        <ErrorFormField :msgArray="errors.common" v-if="errors.common.length > 0" />
        <div class="row g-1">
          <div class="col-md-6">
            <div class="input-group mb-3">
              <span class="input-group-text"><i class="fa fa-user input-icon-swing"></i></span>
              <input
                type="text"
                id="signupFirstName"
                class="form-control label-require"
                placeholder="First Name"
              />
            </div>
            <ErrorFormField :msgArray="errors.first_name" v-if="errors.first_name.length > 0" />
          </div>
          <div class="col-md-6">
            <div class="input-group mb-3">
              <span class="input-group-text"><i class="fa fa-user input-icon-swing"></i></span>
              <input type="text" id="signupLastName" class="form-control" placeholder="Last Name" />
            </div>
          </div>
        </div>

        <div class="input-group mb-3">
          <span class="input-group-text" id="signupIconEmail"
            ><i class="far fa-envelope input-icon-swing"></i
          ></span>
          <input
            type="email"
            id="signupEmail"
            class="form-control label-require"
            placeholder="Email Address"
          />
        </div>
        <ErrorFormField :msgArray="errors.email" v-if="errors.email.length > 0" />
        <div class="input-group mb-3">
          <span class="input-group-text" id="signupIconPassword"
            ><i class="fa fa-lock input-icon-swing"></i
          ></span>
          <input
            type="password"
            id="signupPassword"
            class="form-control label-require"
            placeholder="Password"
          />
        </div>
        <ErrorFormField :msgArray="errors.password" v-if="errors.password.length > 0" />
        <div class="row">
          <div class="col-md-7">
            <div class="input-group mb-3">
              <span class="input-group-text"><i class="far fa-calendar input-icon-swing"></i></span>
              <input
                type="text"
                id="signupDOB"
                class="form-control label-require"
                placeholder="Birthday"
                name="birthday"
              />
            </div>
            <ErrorFormField :msgArray="errors.birthday" v-if="errors.birthday.length > 0" />
          </div>
          <div class="col-md-5">
            <div class="form-check form-check-inline">
              <input
                class="form-check-input"
                type="radio"
                name="signupGender"
                id="signupGenderMale"
                value="1"
                checked
              />
              <label class="form-check-label" for="signupGenderMale">Male</label>
            </div>
            <div class="form-check form-check-inline">
              <input
                class="form-check-input"
                type="radio"
                name="signupGender"
                id="signupGenderFemale"
                value="2"
              />
              <label class="form-check-label" for="signupGenderFemale">Female</label>
            </div>
          </div>
        </div>

        <div class="input-group mb-2">
          <span class="input-group-text" id="signupIconLocation"
            ><i class="fa fa-map-marker input-icon-swing"></i
          ></span>
          <LocationGmap />
        </div>
        <ErrorFormField :msgArray="errors.location" v-if="errors.location.length > 0" />
        <div class="mb-0">
          <div class="form-check d-inline-flex">
            <input class="form-check-input" type="checkbox" value="" id="signupReceiveNews" />
            <label class="form-check-label" for="signupReceiveNews">
              I'd like to receive future communications from Trepr
            </label>
          </div>
        </div>
        <div class="mb-0">
          <p class="desp">
            By clicking Sign up or Continue with, I agree to Trepr's
            <a href="/userterms" data-popup="true" target="_blank">Terms and conditions</a>,
            <a href="/privacy" data-popup="true" target="_blank">Privacy Policy</a> and
            <a href="/securitypolicy" data-popup="true" target="_blank">Non-discrimination Policy</a
            >.
          </p>
        </div>
        <div class="mb-3">
          <LoadingIcons v-if="ajaxLoad.normal" />
          <button
            class="btn btn-primary w-100"
            id="btnsignupModal"
            type="submit"
            @click="btnEmailSignupClick"
            v-else
          >
            Sign up
          </button>
        </div>
        <!--          </form>-->
        <div class="social-buttons d-grid gap-2 mb-3">
          <a class="btn btn-social btn-facebook" @click="btnfacebookHandle">
            <span class="fab fa-facebook 123"> </span> Sign up with Facebook
          </a>
          <a class="btn btn-social btn-google" @click="btnGoogleHandle">
            <span class="fab fa-google-plus"> </span> Sign up with Google
          </a>
          <router-link :to="{ name: 'Login' }" class="btn btn-warning"> Login</router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LoadingIcons from '../../common/LoadingIcons.vue';
import ErrorFormField from '../../common/error/ErrorFormField.vue';
//eslint-disable-next-line
import jQuery from 'jquery';
import Cookies from 'js-cookie';
import LocationGmap from '../../common/form/LocationGmap';

if (typeof window.moment === 'undefined') {
  // the variable is defined
  window.moment = require('moment');
}

// if (typeof window.daterangepicker === 'undefined') {
//   // the variable is defined
//   window.daterangepicker = require('daterangepicker');
// }
window.daterangepicker = require('daterangepicker');
export default {
  name: 'SignUp',
  components: { LocationGmap, ErrorFormField, LoadingIcons },
  data: function () {
    return {
      errors: {
        common: [],
        first_name: [],
        email: [],
        password: [],
        birthday: [],
        location: [],
      },
      ajaxLoad: {
        normal: false,
        facebook: false,
        google: false,
      },
    };
  },
  methods: {
    btnEmailSignupClick: async function () {
      this.$data.ajaxLoad.normal = true;
      let apiUrl = process.env.VUE_APP_API_URL;

      let data = {
        first_name: document.getElementById('signupFirstName').value,
        last_name: document.getElementById('signupLastName').value,
        email: document.getElementById('signupEmail').value,
        password: document.getElementById('signupPassword').value,
        birthday: document.getElementById('signupDOB').value,
        gender: document.querySelector('input[name="signupGender"]:checked').value,
        subscribe: document.getElementById('signupReceiveNews').checked ? 1 : 0,
        location: document.getElementById('signupLocation').value,
        address2: document.getElementById('signupAddress2').value,
        city: document.getElementById('signupCity').value,
        state: document.getElementById('signupState').value,
        zip_code: document.getElementById('signupZipCode').value,
        country: document.getElementById('signupCountry').value,
        lat: document.getElementById('signupLat').value,
        lng: document.getElementById('signupLng').value,
      };

      const formData = Object.keys(data)
        .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
        .join('&');
      // console.log('data signup ', data);
      let self = this;
      await fetch(apiUrl + 'user/signup', {
        method: 'POST',
        headers: {
          // 'Accept': 'application/json',
          'Content-Type': 'application/x-www-form-urlencoded', // application/json application/x-www-form-urlencoded multipart/form-data; boundary=something
        },
        body: formData,
      })
        .then((response) => response.json())
        .then((resData) => {
          console.log('Success:', resData);
          let messages = resData.messages;
          if (resData.status == 'error') {
            if (messages.common.length > 0) self.$data.errors.common = messages.common;
            else self.$data.errors.common = [];

            if (messages.first_name.length > 0) self.$data.errors.first_name = messages.first_name;
            else self.$data.errors.first_name = [];

            if (messages.email.length > 0) self.$data.errors.email = messages.email;
            else self.$data.errors.email = [];

            if (messages.password.length > 0) self.$data.errors.password = messages.password;
            else self.$data.errors.password = [];

            if (messages.birthday.length > 0) self.$data.errors.birthday = messages.birthday;
            else self.$data.errors.birthday = [];

            if (messages.location.length > 0) self.$data.errors.location = messages.location;
            else self.$data.errors.location = [];

            console.log('self.$data ', self.$data);
            self.$data.ajaxLoad.normal = false;
            return false;
          }
          Cookies.set('name', resData.name);
          Cookies.set('image', '');
          Cookies.set('token', resData.token);
          Cookies.set('role', resData.role);
          Cookies.set('totalNotifications', resData.totalNotifications);
          sessionStorage.setItem('notifications', JSON.stringify(resData.notifications));
          Cookies.set('currentRole', 'seeker');
          self.$store.commit('loginUpdate', resData);
          // self.$store.dispatch('loginUpdate', resData)
          console.log('success login');
          self.$router.push('/email-verify');
        })
        .catch((error) => {
          console.error('Error:', error);
        });
      this.$data.ajaxLoad.normal = false;
      console.log(this.$data);
    },

    btnGoogleHandle: async function () {
      this.$data.ajaxLoad.google = true;
      try {
        const googleUser = await this.$gAuth.signIn();
        if (!googleUser) {
          return null;
        }
        console.log('id_token', googleUser.getAuthResponse().id_token);
        let token = googleUser.getAuthResponse().id_token;
        let apiUrl = process.env.VUE_APP_API_URL;
        let data1 = {
          token: token,
        };

        const formData = Object.keys(data1)
          .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data1[key]))
          .join('&');
        let self = this;
        await fetch(apiUrl + 'google-login', {
          method: 'POST',
          headers: {
            // 'Accept': 'application/json',
            'Content-Type': 'application/x-www-form-urlencoded', // application/json application/x-www-form-urlencoded multipart/form-data; boundary=something
          },
          body: formData,
        })
          .then((response) => response.json())
          .then((resData) => {
            console.log('Success:', resData);
            let messages = resData.messages;
            if (resData.status == 'error') {
              if (messages.common.length > 0) self.$data.commonErrors = messages.common;
              else self.$data.commonErrors = [];

              console.log('self.$data ', self.$data);
              return false;
            }

            Cookies.set('name', resData.name);
            Cookies.set('image', resData.image);
            Cookies.set('token', resData.token);
            Cookies.set('role', resData.role);
            Cookies.set('totalNotifications', resData.totalNotifications);
            sessionStorage.setItem('notifications', JSON.stringify(resData.notifications));
            Cookies.set('currentRole', 'seeker');
            Cookies.set('active', resData.active);
            Cookies.set('email_verify', resData.email_verify);
            Cookies.set('phone_verify', resData.phone_verify);
            self.$store.commit('loginUpdate', resData);
            console.log('success login');

            if (resData.active == 1) self.$router.push('/');
            else {
              if (resData.phone_verify == 0) {
                self.$router.push('/phone-verify');
                return;
              }
            }
          })
          .catch((error) => {
            console.error('Error:', error);
          });
        this.$data.ajaxLoad.google = false;
      } catch (error) {
        //on fail do something
        console.error(error);
        return null;
      }
    },
    btnfacebookHandle: async function () {
      // FB.login(async function (response) {
      let self = this;
      //eslint-disable-next-line
      FB.login(async function (response) {
        if (response.status === 'connected') {
          // Logged into your webpage and Facebook.
          console.log('connected ', response);

          self.$data.ajaxLoad.facebook = true;
          try {
            let token = response.authResponse.accessToken;
            let apiUrl = process.env.VUE_APP_API_URL;
            let data1 = {
              token: token,
            };

            const formData = Object.keys(data1)
              .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data1[key]))
              .join('&');
            // let self = this;
            await fetch(apiUrl + 'facebook-login', {
              method: 'POST',
              headers: {
                // 'Accept': 'application/json',
                'Content-Type': 'application/x-www-form-urlencoded', // application/json application/x-www-form-urlencoded multipart/form-data; boundary=something
              },
              body: formData,
            })
              .then((response) => response.json())
              .then((resData) => {
                console.log('Success:', resData);
                let messages = resData.messages;
                if (resData.status == 'error') {
                  if (messages.common.length > 0) self.$data.commonErrors = messages.common;
                  else self.$data.commonErrors = [];

                  console.log('self.$data ', self.$data);
                  return false;
                }
                Cookies.set('name', resData.name);
                Cookies.set('image', resData.image);
                Cookies.set('token', resData.token);
                Cookies.set('role', resData.role);
                Cookies.set('totalNotifications', resData.totalNotifications);
                sessionStorage.setItem('notifications', JSON.stringify(resData.notifications));
                Cookies.set('currentRole', 'seeker');
                Cookies.set('active', resData.active);
                Cookies.set('email_verify', resData.email_verify);
                Cookies.set('phone_verify', resData.phone_verify);
                self.$store.commit('loginUpdate', resData);
                console.log('success login');

                if (resData.active == 1) self.$router.push('/');
                else {
                  if (resData.phone_verify == 0) {
                    self.$router.push('/phone-verify');
                    return;
                  }
                }
              })
              .catch((error) => {
                console.error('Error:', error);
              });
            self.$data.ajaxLoad.facebook = false;
          } catch (error) {
            //on fail do something
            console.error(error);
            return null;
          }
        } else {
          // The person is not logged into your webpage or we are unable to tell.
          console.log('not login ... ', response);
        }
      });
    },
  },

  mounted() {
    // let daterangepicker = require('daterangepicker');
    const $ = jQuery.noConflict();
    let maxYear = window.moment().format('YYYY');
    $('input[name="birthday"]').daterangepicker({
      singleDatePicker: true,
      showDropdowns: true,
      minYear: 1901,
      maxYear: parseInt(maxYear, 10),
      autoApply: true,
      startDate: window.moment().subtract(18, 'years'),
      locale: {
        format: 'DD/MM/YYYY',
      },
    });
    // console.log('sigup wg', window.google);
  },

  unmounted() {
    // delete window.moment;
    // delete window.daterangepicker;
    delete window.google;
  },
};
</script>

<style scoped lang="sass">
@import '../../../../node_modules/daterangepicker/daterangepicker.css'
</style>
